<template>
<v-container fluid class="down-top-padding">
    <v-row>
        <v-col cols="12" sm="12">
            <BaseCard heading="Саволлар - жадвали">
                <v-data-table :headers="headers" :items="rows" :items-per-page="5" :search="search" single-expand="true" show-expand :expanded.sync="expanded">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Қидирув" single-line hide-details></v-text-field>
                            </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="edit = false">
                                        Янги савол
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">Савол</span>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field label="Савол" required v-model="formData.text"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="dialog = false">
                                            Бекор қилиш
                                        </v-btn>
                                        <v-btn color="blue darken-1" text @click="submit">
                                            Сақлаш
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <!-- <v-icon small>
                            mdi-delete
                        </v-icon> -->
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="val in item.AnswerOptions" :key="val.id"  >
                                            <td style="text-align: right;">&#9679;</td>
                                            <td>{{ val.text  }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </td>

                    </template>
                </v-data-table>
            </BaseCard>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {
    getQuestions,
    postQuestions,
    putQuestions
} from '../../api/question'

export default {
    data() {
        return {
            dialog: false,
            edit: false,
            search: '',
            headers: [{
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Савол',
                    value: 'text'
                },
                {
                    text: 'Амаллар',
                    value: 'actions',
                    sortable: false
                },
            ],
            rows: [],
            formData: {
                name: null
            }
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getQuestions().then(res => {
                console.log(res)
                this.rows = res.data
            }).catch(error => {
                console.log(error)
            })
        },
        submit() {
            if (!this.edit) {
                postQuestions(this.formData).then(() => {
                    this.dialog = false
                    this.fetchData()
                    this.resetData()
                }).catch(error => {
                    console.log(error)
                })
            } else {
                putQuestions(this.formData, this.formData.id).then(() => {
                    this.dialog = false
                    this.fetchData()
                    this.resetData()
                }).catch(error => {
                    console.log(error)
                })
            }

        },
        editItem(item) {
            this.dialog = true
            this.formData = item
            this.edit = true

        },
        resetData() {
            this.formData = {
                text: null
            }
        }
    }
}
</script>

<style>
.v-data-table>.v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    -webkit-box-shadow: inset 0px 2px 1px -1px rgba(50, 50, 50, 0.75), inset 0px -2px 1px -1px rgba(50, 50, 50, 0.75) !important;
    box-shadow: inset 0px 2px 1px -1px rgba(50, 50, 50, 0.75), inset 0px -2px 1px -1px rgba(50, 50, 50, 0.75);
}
</style>>
