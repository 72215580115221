import request from '../utils/request'

export function getQuestions(data) {
    return request({
        url: '/api/question',
        method: 'get',
        params: data
    })
}
export function getQuestionsId(id) {
    return request({
        url: '/api/question/'+id,
        method: 'get',
    })
}

export function postQuestions(data) {
    return request({
        url: '/api/question',
        method: 'post',
        data
    })
}

export function putQuestions(data, id) {
    return request({
        url: '/api/question/'+id,
        method: 'put',
        data
    })
}

export function deleteQuestions(id) {
    return request({
        url: '/api/question/'+id,
        method: 'delete'
    })
}
